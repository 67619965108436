// Downloads.js
import React from 'react';
import Header from './Header'; // Import the Header component


const Footer = () => {
    return (
      <footer>
        <p>Copyright © StarTrace 2023</p>
      </footer>
    );
  };


export default Footer;